import { useState } from 'react';
import Loading from '../components/Loading';
import './AddEventPopUp.css';

function AddEventPopUp({ chosenDay, setPopUpWindow, refreshCalendarEvents }) {
    const [statusMessage, setStatusMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // function hideAddEvent(e) {
    //     e.preventDefault();
    //     setPopUpWindow(false);
    // }

    async function sendEvent(time, eventType, eventTopic) {
      setIsLoading(true);
      try {
        const jwtToken = sessionStorage.getItem("jwtToken");
        const request = {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`
          },
          body: JSON.stringify({
            year: chosenDay.getFullYear(),
            month: chosenDay.getMonth(),
            day: chosenDay.getDate(),
            time: time,
            type: eventType,
            topic: eventTopic,
            timezoneOffset: chosenDay.getTimezoneOffset(),
          })
        }
        const result = await fetch(`${process.env.REACT_APP_API_URL}/addEvent`, request);
        const response = await result.json();
        setStatusMessage(response.message);
        if (result.status === 201) {
          refreshCalendarEvents();
          setTimeout(() => {
            setPopUpWindow(false)
          }, 1000);
        }
      } catch(e) {
        setStatusMessage("Ha ocurrido un error.");
      };
      setIsLoading(false);
      }

    function handleClick(e) {
        e.preventDefault();
        const timeInput = document.getElementById("time--input").value;
        const eventType = document.getElementById("event--type").value;
        const eventTopic = document.getElementById("event--topic").value;
        sendEvent(timeInput, eventType, eventTopic);
    }
  return (
    // <div className="add-event--popup-screen">
    //     <div className="add-event--popup-window fade-in-up">
    //         <div className="add-event--popup-window-title">
    //             <div className="add-event--popup-window-title-text">Nuevo Evento</div>
    //             <button className="add-event--popup-window-title-close-btn" onClick={hideAddEvent}>X</button>
    //         </div>
            <form className="add-event--popup-window-body">
                <input type="time" id="time--input" min="08:00" max="20:00" step="900" required />
                <select name="events" id="event--type">
                    <option value="individual-class">Clase Individual</option>
                    <option value="group-class">Clase Grupal (max: 5 estudiantes)</option>
                    <option value="coaching-session">Coaching</option>
                </select>
                <input type="text" id="event--topic" placeholder="Topic" maxLength={50} />
                <div className="add-event--status">{ isLoading ? <Loading /> : statusMessage }</div>
                <button className="add-event--submit-btn" onClick={handleClick}>Confirmar</button>
            </form>
    //     </div>  
    // </div>
  )
}

export default AddEventPopUp;