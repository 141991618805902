import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import LogoutButton from "../components/LogoutButton";
import NotAuthorized from "../components/NotAuthorized";
import "./MyProfile.css";

function MyProfile({ isAuthenticated, setIsAuthenticated, setRescheduleManager, setPopUpWindow }) {
    const [isLoading, setIsLoading] = useState(true);
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate();
    const localeDateOptionsDay = {day: "2-digit", month: "2-digit", year: "2-digit"};
    const localeDateOptionsTime = {hour: "2-digit", minute: "2-digit"};
    const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CLP',
    });

    const eventTypes = {
        "individual-class": {
          text: "Clase Individual",
        },
        "group-class": {
          text: "Clase Grupal",
        },
        "coaching-session": {
          text: "Coaching",
        },
    }
    
    async function handleLogOut() {
        try {
          const result = await fetch(`${process.env.REACT_APP_API_URL}/logout`, { credentials: "include" });
          const response = await result.json();
          console.log(response.message);
          setIsAuthenticated(false);
          setProfileData({});
          sessionStorage.removeItem("jwtToken");
        } catch(e) {
          console.log("Error trying to close session");
        }
    }

    function handleReschedule(id, date, type) {
        setRescheduleManager({ 
            prevEvent: {
                id: id,
                date: date,
                type: type,
            },
            newEvent: false 
        })
        navigate("/agenda");
    }

    async function fetchMyProfile() {
        try {
            const jwtToken = sessionStorage.getItem("jwtToken");
            const request = {
                method: "GET",
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`
                },
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/myProfile`, request);
            const response = await result.json();
            setProfileData({
                history: response.history.reverse(),
                purchases: response.purchases,
                upcoming: response.upcoming,
            });
            setIsLoading(false);
        } catch(e) {
            console.log("There was en error retrieving profile information")
        }
    }

    async function handleParticipants(eventId) {
        try {
            const jwtToken = sessionStorage.getItem("jwtToken");
            const request = {
                method: "POST",
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    eventId: eventId,
                })
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/getParticipants`, request);
            const response = await result.json();
            const content = response.users.map((participant, index) => {
                return <div key={index} className="popup-window-body--row">
                  <div className="profile--logo"></div>
                  <div><b>{participant.firstName}</b></div>
                  <div><b>{participant.lastName}</b></div>
                  <div>{participant.email}</div>
                </div>
              })

            if (result.status === 200) {
                setPopUpWindow({
                    title: "Inscritos",
                    content: content,
                });
                return
            }

            setPopUpWindow({
                title: "Error",
                content: "¡Ha ocurrido un error!",
            });

        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (isAuthenticated !== false) {
            setIsLoading(true);
            fetchMyProfile();
        }
    }, [isAuthenticated])
    
    return (
        <>
        {isAuthenticated === false ? <NotAuthorized /> : 
            isLoading ? <Loading /> : (
            <>
                <div className="my-profile--header fade-in-0">
                    <div className="my-profile--header-title">
                        <img src="/profile.svg" alt="Mi perfil" />
                        <h1>{isAuthenticated.firstName}</h1>
                        <h5>{isAuthenticated.user}</h5>
                    </div>
                    <LogoutButton handleLogOut={handleLogOut} />
                </div>      
                <div className="my-profile--container">
                    <div className="my-profile--body fade-in-up">
                        <div className="my-profile--upcoming">
                            <div className="my-profile--body-header green--header">Próximos eventos</div>
                            <div className="my-profile--box">
                            <div className="my-profile--box-desc"><div>Fecha</div> <div>Hora</div> <div>Tipo</div> <div>Link</div></div>
                            {(!profileData.upcoming || profileData.upcoming.length === 0) ? <div className="no--events">No hay información de eventos.</div> : 
                                profileData.upcoming.map((event, index) => {
                                    const eventDate = new Date(event.date);
                                    return (
                                    <div key={index} className="my-profile--item-row my-profile--event">
                                        <div className="my-profile--event-date">
                                            {event.canBeRescheduled && <button className="my-profile--event-date--reschedule" onClick={() => {handleReschedule(event.id, eventDate, event.type)}}></button>}
                                            <div className={`my-profile--event-date--text bg-${eventDate.getMonth()+1}`}>{eventDate.toLocaleDateString(undefined, localeDateOptionsDay)}</div>
                                        </div>
                                        <div className="my-profile--event-time">
                                            <div className="my-profile--event-time--text">
                                                {eventDate.toLocaleTimeString(undefined, localeDateOptionsTime).substring(0,5)}
                                                {eventDate.getHours() >= 12 ? " pm" : " am"}
                                            </div>
                                        </div>
                                        <div className="my-profile--event-type">
                                            {eventTypes[event.type].text}
                                            {isAuthenticated.role === "admin" && <button className="participants-btn" onClick={() => {handleParticipants(event.id)}}></button>}
                                            {event.rescheduled > 0 && <><br></br>Reagendado: {event.rescheduled}</>}
                                        </div>
                                        <div className="my-profile--event-type">
                                            <a className="my-profile--event--link" href={event.url} target="_blank" rel="noopener noreferrer">{isAuthenticated.role === "admin" ? "Iniciar" : "Unirse"}</a>
                                        </div>
                                    </div>
                                )
                                })}
                            </div>
                        </div>
                        <div className="my-profile--history">
                            <div className="my-profile--body-header red--header">Historial</div>
                            <div className="my-profile--box">
                            <div className="my-profile--box-desc"><div>Fecha</div> <div>Hora</div> <div>Tipo</div> <div>Duración</div></div>
                            {(!profileData.history ||profileData.history.length === 0) ? <div className="no--events">No hay información de eventos.</div> : 
                                profileData.history.map((event, index) => {
                                    const eventDate = new Date(event.date);
                                    return (
                                    <div key={index} className="my-profile--item-row my-profile--event">
                                        <div className={`my-profile--event-date--text bg-${eventDate.getMonth()+1}`}>
                                            {eventDate.toLocaleDateString(undefined, localeDateOptionsDay)}
                                        </div>
                                        <div className="my-profile--event-time--text">
                                            {eventDate.toLocaleTimeString(undefined, localeDateOptionsTime).substring(0,5)}
                                            {eventDate.getHours() >= 12 ? " pm" : " am"}
                                        </div>
                                        <div className="my-profile--event-type">
                                            {eventTypes[event.type].text}
                                            {isAuthenticated.role === "admin" && <button className="participants-btn" onClick={() => {handleParticipants(event.id)}}></button>}
                                            {event.rescheduled > 0 && <><br></br>Reagendado: {event.rescheduled}</>}
                                        </div>
                                        <div className="my-profile--event-type">
                                            {
                                                new Date(eventDate.getTime() + (20 * 60 * 1000)) > new Date() ?  
                                                    <><div className="in-progress">En progreso...</div><a className="my-profile--event--link" href={event.url} target="_blank" rel="noopener noreferrer">{isAuthenticated.role === "admin" ? "Iniciar" : "Unirse"}</a></> : 
                                                    event.duration
                                            }
                                        </div>
                                    </div>
                                )
                                })}
                            </div>
                        </div>
                        <div className="my-profile--purchases">
                            <div className="my-profile--body-header blue--header"><div className="my-profile--body-header--container"><div className="purchases-icon"></div>Compras</div></div>
                            <div className="my-profile--box">
                            <div className="my-profile--box-desc"><div>Fecha</div> <div>Código</div> <div>Eventos</div> <div>Precio</div></div>
                                {(!profileData.purchases || profileData.purchases.length === 0) ? <div className="no--events">No hay información de compras.</div> : 
                                profileData.purchases.map((purchase, index) => {
                                    const purchaseDate = new Date(purchase.date);
                                    return (
                                    <div key={index} className="my-profile--item-row my-profile--event">
                                        <div className="my-profile--event-date">
                                            {purchase.canBeReimbursed && <div className="my-profile--event-date--reimbursement">!</div>}
                                            <div className={`my-profile--event-date--text bg-${purchaseDate.getMonth()+1}`}>{purchaseDate.toLocaleDateString(undefined, localeDateOptionsDay)}</div>
                                        </div>
                                        <div className="my-profile--event-type">
                                            {purchase.orderId}
                                        </div>
                                        <div className="my-profile--event-type">
                                            {purchase.eventNumber}
                                        </div>
                                        <div className="my-profile--event-type">
                                            {priceFormatter.format(purchase.price)}
                                        </div>
                                    </div>
                                )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        </>
    )
}

export default MyProfile;