// import { useState } from "react";
import "./PopUpWindow.css";

function PopUpWindow({ setPopUpWindow, title, content }) {
  // const [fullScreen, setFullScreen] = useState(false);

  return (
    <div className="popup-screen fade-in">
      <div className="popup-window fade-in-up">
        <div className="popup-window-title">
          {/* <button className="popup-window-title-close-btn" onClick={() => { setFullScreen((bool) => {return !bool}) }}>x</button> */}
          <div></div>
          <div>{title}</div>
          <button className="popup-window-title-close-btn" onClick={() => {setPopUpWindow(false)}}>x</button>
        </div>
        <div className="popup-window-body">
          {content}
        </div>
        <div className="popup-window-footer"></div>
      </div>
    </div>
  )
}

export default PopUpWindow;