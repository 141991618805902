import { useState } from 'react';
import Loading from '../components/Loading';
import './LoginPopUp.css';

function LoginPopUp({ hideLogin, setLoginPopUp, setIsAuthenticated }) {
    const [statusMessage, setStatusMessage] = useState(process.env.REACT_APP_ALLOW_REGISTRATION === "1" ? <div className="response--message">¿No tienes una cuenta? Haz clic en 'Registro'.</div> : <div className="notification">Hemos puesto en pausa el registro de nuevos estudiantes.</div>);
    const [isLoading, setIsLoading] = useState(false);

    async function sendLogin(email, pw) {
        try {
            const request = {
                method: "POST",
                credentials: "include" ,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: pw,
                })
            };
            const result = await fetch(`${process.env.REACT_APP_API_URL}/login`, request);
            const response = await result.json();
            setStatusMessage(<div className="fade-in">{response.message}</div>);
            if (result.status === 201) {
                sessionStorage.setItem("jwtToken", response.jwtToken);
                setIsAuthenticated({
                    user: response.user,
                    role: response.role,
                    firstName: response.firstName,
                });
                setTimeout(() => {
                    setLoginPopUp(false);
                }, 1000)
            }
        } catch {
            setStatusMessage(<div className="response--message fade-in">No es posible iniciar sesión en este momento...</div>);
        }
        setIsLoading(false);
    }

    function submitClick(e) {
        e.preventDefault();
        setIsLoading(true);
        const emailInput = document.getElementById("email--input");
        const pwInput = document.getElementById("pw--input");
        if (!emailInput.checkValidity()) {
            setStatusMessage(<div className="response--message fade-in">Porfavor ingresar una dirección de correo válida.</div>);
            setTimeout(() => {
                setIsLoading(false);
            }, 50)
            return
        }
        if (!pwInput.checkValidity()) {
            setStatusMessage(<div className="response--message fade-in">La contraseña debe tener entre 8 y 32 caracteres.</div>);
            setTimeout(() => {
                setIsLoading(false);
            }, 50)
            return
        }
        sendLogin(emailInput.value, pwInput.value);
    }

  return (
    <div className="login--popup-screen fade-in">
        <div className="login--popup-window fade-in-up">
            <div className="login--popup-window-title">
                <div></div>
                <div className="login--popup-window-title-login-btn"><div className="lock--logo"></div><div>Acceso</div></div>
                <button className="login--popup-window-title-close-btn" onClick={hideLogin}>X</button>
            </div>
            <form className="login--popup-window-body">
                <div className="login--popup-window-email">
                    <input type="email" id="email--input" pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}" size="20" placeholder="Email" autoComplete="username" required />
                </div>
                <div className="login--popup-window-pw">
                    <input type="password" id="pw--input" size="20" minLength={8} maxLength={32} placeholder="Contraseña" autoComplete="current-password" required />
                </div>
                <div className="login--popup-window-free">{isLoading ? <Loading /> : statusMessage}</div>
                <div className="login--popup-window-submit"><button className="press-down" onClick={submitClick}>Ingresar</button> </div>
                <div className="login--popup-window-register">{process.env.REACT_APP_ALLOW_REGISTRATION === "1" && <a href="/registro">Registro</a>}</div>
            </form>
        </div>  
    </div>
  )
}

export default LoginPopUp;