import Loading from "./Loading";
import ErrorSign from "./ErrorSign";
import BuyButton from "./BuyButton";
import RescheduleButton from "./RescheduleButton";
import AdminButtons from "./AdminButtons";
import AddEventPopUp from '../components/AddEventPopUp';

function EventScheduler({ isAuthenticated, isLoading, error, chosenDay, filteredEvents, addToCart, rescheduleManager, setRescheduleManager, isInCart, refreshCalendarEvents, setPopUpWindow }) {
  const eventTypes = {
    "individual-class": {
      title: "Clase Individual",
      icon: "/event-individual.svg",
      maxParticipants: 1,
      description: <p>Mejora tu inglés con clases personalizadas adaptadas a tus <b>necesidades</b>, <b>objetivos</b> e <b>intereses</b>. Cada clase presenta un tema preparado en función de tus preferencias, centrado en las <b>habilidades conversacionales</b>, e incluye <b>materiales</b> para practicar en casa.</p>,
    },
    "group-class": {
      title: "Clase Grupal",
      icon: "/event-group.svg",
      maxParticipants: 4,
      description: <p>Practica tus habilidades de <b>conversación</b> y aprende <b>vocabulario</b> en contexto. Cada clase cubre un nuevo tema con actividades como <b>discusiones</b>, <b>debates</b> y <b>juegos</b>. Después de la clase se envían los materiales y tareas adicionales para practicar en casa.</p>,
    },
    "coaching-session": {
      title: "Coaching",
      icon: "/event-coaching.svg",
      maxParticipants: 1,
      description: <p>Sesiones individuales con orientación personalizada para el éxito <b>profesional</b> o <b>académico</b>. Nos enfocamos en tus prioridades: elaboración de un <b>currículum</b>, preparación para una <b>entrevista</b> o habilidades de <b>presentación</b>. Cada sesión ofrece información clara y medidas prácticas para ayudarte a tener éxito.</p>,
    },
  }

  const instructors = {
    "mc1": {
      name: "Maggie",
      description: <p>Hi! I'm Maggie, an English teacher and educational content creator from the U.S., now living in Chile. I have over 5 years of experience teaching English to adults, focusing on business (meetings and negotiations), travel, and job and scholarship applications. I'm also the founder of Charm English.</p>,
      bullet_points: <ul>
        <li>English teacher from U.S. based in Chile</li>
        <li>5+ years of experience teaching english to adults</li>
        <li>Experience with english for business (meetings and negotiations), travel, and job and scholarship applications</li>
        <li>Founder of Charm English</li>
      </ul>
    },
    "ec1": {
      name: "Emily",
      description: <p>Hi! I'm Emily. I'm an English teacher from the U.S. living in Scotland. I have a Master's in teaching English as a second language and experience tutoring university and graduate students. Formerly an education mediation specialist, I use this experience to create a supportive classroom environment. I'm passionate about linguistics and have lived and studied in Paraguay, Mexico, and Spain. </p>,
      bullet_points: <ul>
        <li>English teacher from the U.S. living in Scotland</li>
        <li>Master's in teaching English as a second language</li>
        <li>Experience tutoring university and graduate students</li>
        <li>Formerly an education mediation specialist</li>
        <li>Passionate about linguistics and has lived and studied in Paraguay, Mexico, and Spain</li>
      </ul>
    },
    "kitty": {
      name: "kitty",
      description: <p>text</p>,
      bullet_points: <ul>
        <li>bullet</li>
        <li>bullet</li>
        <li>bullet</li>
        <li>bullet</li>
      </ul>
    }
  }

  function handleClick(e) {
    setPopUpWindow({
      title: "Nuevo Evento",
      content: <AddEventPopUp chosenDay={chosenDay} setPopUpWindow={setPopUpWindow} refreshCalendarEvents={refreshCalendarEvents} />,
    });
  }

  function displayInstructor(id) {
    setPopUpWindow({
      title: "Instructor",
      content: 
      <div className="popup--profile">
        <div className="pic--name">
          <img className="profile-pic-2 round-border" src={`/profiles/pp${id}.png`} alt={instructors[id].name} />
          <div className="profile--name">{instructors[id].name} ✨</div>
        </div>
        <div className="profile--description">{instructors[id].description}</div>
        <div className="profile--bullet ok--bullet">{instructors[id].bullet_points}</div>
      </div>,
    })
  }

  function displayEventInfo(event) {
    const startTime = new Date(event.date);

    setPopUpWindow({
      title: eventTypes[event.type].title,
      content: <div className="popup--profile">
      <div className="pic--name">
        <img className="profile-pic-2" src={`${eventTypes[event.type].icon}`} alt={eventTypes[event.type].title} />
        <div className="profile--topic">{event.topic ? event.topic : eventTypes[event.type].title}</div>
      </div>
      <div className="profile--description">
        {eventTypes[event.type].description}
      </div>
      <div className="profile--bullet glitter--bullet">
        <ul>
          <li>Fecha: {startTime.toLocaleTimeString(undefined, {year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})}</li>
          <li>Duración: {event.duration/60 >= 1 ? Math.floor(event.duration/60) + "h" : ""}{event.duration % 60 === 0 ? "" : " " + (event.duration % 60) + "m"}</li>
          <li>Inscritos: <b>{event.participants}</b> de {eventTypes[event.type].maxParticipants}</li>
        </ul>
      </div>
    </div>,
    })
  }

  return (
    <div className="event-scheduler--container fade-in-3">
        <div id="event-scheduler" className={`event-scheduler--body${filteredEvents.length > 0 ? " body-available" : ""}`}>
            <div className="event-scheduler--title">
              {isAuthenticated && isAuthenticated.role === "admin" && <button className="event-scheduler--title-add-btn" onClick={handleClick}>+</button>}
              <div className="event-scheduler--title-day">
                <div className="event-scheduler--title-month">{chosenDay.toLocaleDateString('es-US', {month: 'short'}).toUpperCase()}</div>
                <div className="event-scheduler--title-number">{chosenDay.getDate()}</div>
              </div>
              <p>Eventos</p>
              {filteredEvents.length > 0 && <div className="event-scheduler--title-tag yellow"><b>{filteredEvents.length}</b> Disponible</div>}
            </div>
            {filteredEvents.length > 0 && <div className={`event-scheduler--message${isAuthenticated.role === "admin" ? " message--admin" : ""}`}>{isAuthenticated.role === "admin" ? "Vista Admin" : "Horarios ajustados a tu hora local"}</div>}
            {isLoading ? <Loading /> : 
              error === true ? <ErrorSign /> : (
                <div className="event-scheduler--list">
                {filteredEvents.length === 0 && <><div className="event-scheduler--list-no-items fade-in-0">No hay eventos para esta fecha, selecciona otro día.</div><img className="fade-in-0 empty-kitty" src="/kitty_face.png" alt="Michi :)" width={100} height={100} /></>}
                {filteredEvents.map((event, index) => {
                const startTime = new Date(event.date)
                const startTimeText = startTime.toLocaleTimeString(undefined, {hour: "2-digit", minute: "2-digit"});
                return (<div className={`event-scheduler--event slide-in-${ index < 4 ? index + 1 : 4 } fade-in-now`} key={index}>
                    <div className="event-scheduler--event-time">
                      {startTimeText.substring(0,5)}
                      {<p>{startTime.getHours() >= 12 ? "pm" : "am"}</p>}
                    </div>
                    <button className="event-scheduler--event-type" onClick={() => {displayInstructor(event.organizer)}}>
                      <img className="profile-pic" src={`/profiles/pp${event.organizer}.png`} alt={instructors[event.organizer].name} />
                      <div className="event-scheduler--event-type-title">{instructors[event.organizer].name}</div>
                    </button>
                    <button className="event-scheduler--event-duration" onClick={() => {displayEventInfo(event)}}>
                      {eventTypes[event.type].maxParticipants > 1 ? <div className="event-scheduler--event-topic">{event.topic}</div> :
                       <div className="event-scheduler--event-topic">{eventTypes[event.type].title}</div>}
                      <div className="event-scheduler--event-duration-time">
                        <div className="event-duration-time--box">
                          <img src="/clock.svg" alt="Duración"></img>{event.duration/60 >= 1 ? Math.floor(event.duration/60) + "h" : ""}{event.duration % 60 === 0 ? "" : " " + (event.duration % 60) + "m"}
                        </div>
                      </div>
                      <div className="event-scheduler--event-duration-time">
                        <div className="event-duration-time--box">
                          <img src="/user.svg" alt="Inscritos"></img>
                          <div className="event-scheduler--event-participants"><span className="event-participants--counter">{event.participants}</span>/{eventTypes[event.type].maxParticipants}</div>
                        </div>
                      </div>
                    </button>
                    {isAuthenticated !== false ? 
                      isAuthenticated.role === "admin" ? <AdminButtons event={event} refreshCalendarEvents={refreshCalendarEvents} numParticipants={event.participants} /> :
                      rescheduleManager.prevEvent !== false ? 
                        rescheduleManager.prevEvent.type === event.type ? 
                          <RescheduleButton setRescheduleManager={setRescheduleManager} event={event} /> : 
                          <div className="event-scheduler--buy-btn-placeholder">No aplicable</div> : 
                        <BuyButton event={event} addToCart={addToCart} isInCart={isInCart} /> : 
                      <BuyButton event={event} addToCart={addToCart} isInCart={isInCart} />}
                  </div>)
                })}
              </div>
            )}
        </div>
    </div>
  )
}

export default EventScheduler;